import { Container, checkMoney, isMoney } from '@ivorobioff/shared';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import AccountService from '../../../services/AccountService';
import PreferenceService, { calcExchangedAmount } from '../../../services/PreferenceService';
import PlanService from '../../../services/PlanService';
import { UserService } from '../../../services/UserService';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Plan from '../../../models/Plan';
import Account from '../../../models/Account';
import Preference from '../../../models/Preference';
import PlanOperationService from '../../../services/PlanOperationService';
import { OperationIntent } from '../../../models/Operation';
import ExpenseOperationView from './ExpenseOperationView';
import ExpenseControl from './ExpenseControl';
import SubmitButton from './SubmitButton';
import ExpenseActions from './ExpenseActions';
import ExpenseAlert from './ExpenseAlert';
import ReceiptIcon from '@material-ui/icons/Receipt';

interface AddExpensesPageProps {
  container: Container;
}

interface FormData {
  account: string;
  plan: string;
  amount: string;
  note?: string;
}

export default function AddExpensesPage({ container }: AddExpensesPageProps) {

  const accountService = container.get(AccountService);
  const preferenceService = container.get(PreferenceService);
  const userService = container.get(UserService);
  const planService = container.get(PlanService);
  const planOperationService = container.get(PlanOperationService);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [preference, setPreference] = useState<Preference>();
  const [applyExchangeRate, setApplyExchangeRate] = useState<boolean>(false);
  const [exchangedAmount, setExchangedAmount] = useState<string>('');
  const [success, setSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const { resetField, control, handleSubmit, formState: { isDirty, isValid }, setValue } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      amount: '',
      note: '',
      account: '',
      plan: ''
    }
  });

  useEffect(() => {
    combineLatest([accountService.getAll(), userService.get()]).subscribe(([accounts, user]) => {
      setAccounts(accounts);
      setValue('account', user.activeAccountId);
    });
  }, [accountService, userService, setValue]);

  const selectedAccountId = useWatch({ control, name: 'account' });
  const amount = useWatch({ control, name: 'amount' })

  useEffect(() => {
    if (selectedAccountId) {
      combineLatest([planService.getAll(selectedAccountId), preferenceService.get(selectedAccountId)])
        .subscribe(([plans, preference]) => {
          setPlans(plans);
          setPreference(preference);
          setValue('plan', plans[0]?.id || '');
        });

      setApplyExchangeRate(false);
      setExchangedAmount('');
    }
  }, [selectedAccountId, planService, preferenceService, setValue]);

  useEffect(() => {
    if (!applyExchangeRate) {
      return;
    }

    if (isMoney(amount)) {
      setExchangedAmount(calcExchangedAmount(amount, preference));
    } else {
      setExchangedAmount('');
    }
  }, [preference, amount, applyExchangeRate]);

  const formProcessor = async (data: FormData) => {
    setSuccess(null);
    setLoading(true);

    const selectedPlanId = data.plan;
    const accountId = data.account;
    const note = data.note;
    const selectedPlan = plans.find(plan => plan.id === selectedPlanId)!;
    const amount = parseFloat(applyExchangeRate ? exchangedAmount : data.amount);

    try {
      const submittedAmounts = await planOperationService.submitForAvailable(selectedPlan, {
        amount,
        note,
        intent: OperationIntent.Minus
      }, { accountId });

      setPlans(plans.map(plan => plan.id === selectedPlanId
        ? { ...plan, ...submittedAmounts }
        : plan));
      resetField('amount');
      resetField('note');

      setApplyExchangeRate(false);
      setExchangedAmount('');
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setSuccess(false);
      setLoading(false);
      console.log(e);
    }
  };

  const closeAlert = () => setSuccess(null);
  const cannotSubmit = !isDirty || !isValid || loading;

  return <ExpenseOperationView backIcon={<ReceiptIcon />} backPath="/calculate-expenses" title="Add Expense">
    <form onSubmit={handleSubmit(formProcessor)}>
      <ExpenseControl>
        <Controller name="account" control={control}
          rules={{
            required: `It's required!`
          }}
          render={({ field, fieldState: { invalid, error } }) =>
            <FormControl fullWidth error={invalid}>
              <InputLabel>Account</InputLabel>
              <Select {...field}>
                {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
              </Select>
              {invalid && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>} />
      </ExpenseControl>

      <ExpenseControl>
        <Controller name="plan" control={control}
          rules={{
            required: `It's required!`
          }}
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) =>
            <FormControl fullWidth error={invalid}>
              <InputLabel>Plan</InputLabel>
              <Select value={value} onChange={onChange}>
                {value && plans.some(plan => plan.id === value)
                  ? plans.map(plan => <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>)
                  : <MenuItem value={value}></MenuItem>}
              </Select>
              {invalid && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>} />
      </ExpenseControl>

      <ExpenseControl>
        <Controller name="amount" control={control}
          rules={{
            validate: checkMoney
          }}
          render={({ field, fieldState: { invalid, error } }) =>
            <TextField type="number"
              label={`Amount ${applyExchangeRate ? `(${preference?.spendingCurrency || 'Spending Currency'})` : ''}`}
              fullWidth
              error={invalid}
              helperText={error?.message}
              {...field} />} />
        {preference?.exchangeRate && (<>
          {applyExchangeRate && <>
            <Box mb={1} />
            <TextField type="text"
              value={exchangedAmount}
              label={`Amount (${preference?.incomeCurrency || 'Income Currency'})`}
              fullWidth
              disabled={true} />
          </>}
          <Box mb={1} />
          <FormControl fullWidth>
            <FormControlLabel control={
              <Checkbox color="primary" checked={applyExchangeRate} onChange={e => setApplyExchangeRate(e.target.checked)} />
            } label={`Use exchange rate: ${preference.exchangeRate}`} />
          </FormControl>
        </>)}
      </ExpenseControl>

      <ExpenseControl last>
        <Controller name="note" control={control}
          render={({ field, fieldState: { error, invalid } }) =>
            <TextField label="Note" multiline fullWidth {...field} error={invalid} helperText={error?.message} />} />
      </ExpenseControl>

      <ExpenseAlert onClose={closeAlert} message={success === true ? 'The expense has been successfully added!' : undefined} />
      <ExpenseAlert onClose={closeAlert} error message={success === false ? 'The expense has not been added due to error!' : undefined} />
      
      <ExpenseActions><SubmitButton disabled={cannotSubmit} loading={loading} /></ExpenseActions>
    </form>
  </ExpenseOperationView>
}