import { isMoney, isPositiveFloat, isEmail, isFloat, isZeroOrPositiveInt, isPositiveInt, isZeroOrPositiveFloat, isMoment, isPresentOrFuture } from "./utils";

export function checkAll<T = unknown>(...checkers: ((value: T) => string | undefined)[]): (value: T) => string | undefined {
  return (v) => {
    for (let checker of checkers) {
      const error = checker(v);

      if (error) {
        return error;
      }
    }

    return undefined;
  }
}

export function checkPositiveInt(value: any): string | undefined {
  return isPositiveInt(value) ? undefined : 'Must be a positive whole number'
}

export function checkZeroOrPositiveInt(value: any): string | undefined {
  return isZeroOrPositiveInt(value) ? undefined : 'Must be zero or a positive whole number'
}

export function checkZeroOrPositiveFloat(value: any): string | undefined {
  return isZeroOrPositiveFloat(value) ? undefined : 'Must be zero or a positive whole/fractional number'
}

export function checkPositiveFloat(value: any): string | undefined {
  return isPositiveFloat(value) ? undefined : 'Must be a positive whole/fractional number'
}

export function checkMoney<T = any>(value: T): string | undefined {
  return isMoney(value) ? undefined : 'Invalid money format'
}

export function checkEmail(value: any): string | undefined {
  return isEmail(value) ? undefined : 'Invalid email address';
}

export function checkMax(value: any, max: number): string | undefined {

  if (!isFloat(value)) {
    throw new Error('Number is expected!');
  }

  if (value > max) {
    return `must be less than, or equal to ${max}`;
  }

  return undefined;
}

export function checkLength(value: any, min: number, max?: number) {
  if (typeof value !== 'string') {
    throw new Error('String is expected!');
  }

  let message = `must contain min. ${min}`;

  if (max) {
    message += `, and max. ${max}`
  }

  message += ' characters';

  let length = value.length;

  if (length < min) {
    return message;
  }

  if (max && length > max) {
    return message;
  }

  return undefined;
}

export function checkMoment(format: string) {
  return (value: any) => {
    if (!isMoment(value, format)) {
      return 'Must be ' + format;
    }

    return undefined;
  }
}

export function checkPresentOrFuture(format: string) {
  return (value: any) => {
    if (!isPresentOrFuture(value, format)) {
      return 'Must be present or future';
    }

    return undefined;
  }
}