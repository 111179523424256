import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface ExpenseControlSectionProps {
  children: ReactNode;
  last?: boolean;
}

export default function ExpenseControl({ children, last }: ExpenseControlSectionProps) {
  return <>
    <Box mt={1} />
    {children}
    <Box mb={last ? 3 : 1} />
  </>;
}