import { Box, Button, CircularProgress, IconButton, InputAdornment, LinearProgress, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Container } from '@ivorobioff/shared';
import { History } from 'history';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import PocService, { SuggestedExpense } from '../../../services/PocService';
import PlanService from '../../../services/PlanService';
import { Alert } from '@material-ui/lab';
import Plan from '../../../models/Plan';
import PlanOperationService from '../../../services/PlanOperationService';
import { OperationIntent } from '../../../models/Operation';

const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const ThinkingBar = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export interface AddExpensesWithAiPageProps {
  container: Container
}

const accountId = '63680b982e75a1757ecbca6a'; // RON
const suggestionToPlan = {
  food: '65195ab60263d52122941182',
  fuel: '6640f8e1cd54065364ca542e',
  utilities: '6394a1b9dee33c06f67f14bb',
  other: '652556650263d521229411b3'
};

export default function AddExpensesWithAiPage({ container }: AddExpensesWithAiPageProps) {

  const pocService = container.get(PocService);
  const planService = container.get(PlanService);
  const planOperationService = container.get(PlanOperationService);

  const loadedPlans = useRef<Map<string, Plan>>();

  const [submitting, setSubmitting] = useState(false);
  const [thinking, setThinking] = useState(false);
  const [description, setDescription] = useState('');
  const [suggestions, setSuggestions] = useState<(SuggestedExpense & { plan: Plan })[]>([]);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState<string | null>(null);

  const canSubmit = !submitting && !thinking && suggestions.length > 0;
  const canPrompt = description.trim() !== '' && !submitting && !thinking;

  const loadPlans = async () => {
    if (!loadedPlans.current) {
      const plans = await planService.getAllAsync(accountId);
      loadedPlans.current = new Map(plans.map(plan => [plan.id, plan]));
    }

    return loadedPlans.current;
  };

  const askSuggestions = async () => {
    try {
      setThinking(true);
      const plans = await loadPlans();
      const suggestions = await pocService.getSuggestedExpenses(description);

      setSuggestions(suggestions.map(suggestion => {
        const planId = suggestionToPlan[suggestion.category];
        const plan = plans.get(planId);

        if (!plan) {
          throw new Error(`Plan[${planId}] not found!`);
        }

        return { ...suggestion, plan };
      }));

    } catch (e) {
      setFailure('Failed to get suggestions from AI due to an error!');
      setSuccess(false);
      console.error(e);
    } finally {
      setThinking(false);
    }
  };

  const submit = async () => {
    setSubmitting(true);

    setFailure(null);
    setSuccess(false);

    try {
      for (const { cost, notes, plan } of suggestions) {
        const amounts = await planOperationService.submitForAvailable(plan, {
          amount: cost,
          intent: OperationIntent.Minus,
          note: notes
        });

        Object.assign(plan, amounts);
      }

      setSubmitting(false);
      setSuggestions([]);
      setDescription('');
      setSuccess(true);
    } catch (e) {
      setFailure('Failed to submit expenses due to an error!');
      console.error(e);
    }
  };

  const history = container.get<History>('history');
  return <Box marginTop={3} paddingX={1}>
    <Paper variant="outlined">
      <Box padding={2}>
        <PageHeader>
          <Typography component="h2" variant="h6" color="primary">Add Expenses with AI</Typography>
          <IconButton color="secondary" onClick={() => history.push('/add-expenses')}>
            <QueuePlayNextIcon />
          </IconButton>
        </PageHeader>
        <br />

        <TextField label="Description" multiline fullWidth
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
          InputProps={{
            endAdornment: <InputAdornment position="end" >
              <IconButton
                disabled={!canPrompt}
                onClick={askSuggestions}>
                <CompareArrowsIcon />
              </IconButton>
            </InputAdornment>
          }} />

        {suggestions.length > 0 && !thinking && <>
          <h3>AI Suggestions</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>Spent</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suggestions.map(({ cost, notes, plan: { name } }, i) => <TableRow key={i}>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="center">{cost}</TableCell>
                <TableCell align="left">{notes}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </>
        }

        {thinking && <ThinkingBar color="secondary" />}
        <Box mt={3}>
          {success && <Alert severity="success" onClose={() => setSuccess(false)}>The expenses have been successfully added!</Alert>}
          {failure && <Alert severity="error" onClose={() => setFailure(null)}>{failure}</Alert>}
        </Box>

        <Box textAlign="right" marginTop={3}>
          <Box component="div" position="relative" display="inline-block">
            <Button variant="contained"
              color="primary"
              type="submit"
              onClick={submit}
              disabled={!canSubmit}>Submit</Button>
            {submitting && <Box
              position="absolute"
              top="0"
              marginTop={0.8}
              marginLeft={-1.3}
              left="50%"><CircularProgress size={24} /></Box>}
          </Box>
        </Box>
      </Box>
    </Paper>
  </Box >
}