import React, { useEffect, useState } from 'react';
import ExpenseOperationView from './ExpenseOperationView';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import Account from '../../../models/Account';
import Plan from '../../../models/Plan';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ExpenseControl from './ExpenseControl';
import SubmitButton from './SubmitButton';
import ExpenseActions from './ExpenseActions';
import useService from '@ivorobioff/shared/src/ioc/useService';
import AccountService from '../../../services/AccountService';
import PlanService from '../../../services/PlanService';
import { UserService } from '../../../services/UserService';
import { Controller, useForm } from 'react-hook-form';
import ExpenseAlert from './ExpenseAlert';
import { checkAll, checkMoney } from '@ivorobioff/shared';
import PlanOperationService from '../../../services/PlanOperationService';
import { OperationIntent } from '../../../models/Operation';

interface CalculationData {
  total: string;
  transactions?: string;
  accountId: string;
  planId: string;
  note?: string;
}

const checkTransactions = (value: string = '') => /^[\d\s]*$/.test(value)
  ? undefined
  : 'It can contain only integers, spaces, and new lines';


function parseTransactions(transactions: string = ''): [number, number[]] {
  const sanitizedTransactions = transactions.trim();

  const amounts = sanitizedTransactions === ''
    ? []
    : sanitizedTransactions.split(/\s/)
      .filter(value => value !== '')
      .map(value => Number(value.trim()));

  const amount = amounts.reduce((result, amount) => result + amount, 0);

  return [amount, amounts];
};

const checkConsistency = (transactions: string = '', total: string = '') => {
  if (total.trim() === '') {
    return undefined;
  }

  const [amount, amounts] = parseTransactions(transactions);

  if (amounts.length === 0) {
    return undefined;
  }

  return Number(total) < amount ? 'must be less or equal to Total' : undefined;
};

function calculateAmount(total: string, transactions?: string) {
  const totalAmount = Number(total);

  const [amount, amounts] = parseTransactions(transactions);

  const everything = totalAmount === amount || amounts.length === 0;

  return {
    calculatedAmount: amounts.length === 0 ? totalAmount : amount,
    remainingAmount: totalAmount - amount,
    everything,
  };
}

export default function CalculateExpensesPage() {
  const accountService = useService(AccountService);
  const planService = useService(PlanService);
  const userService = useService(UserService);
  const planOperationService = useService(PlanOperationService);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [initiated, setInitiated] = useState(false);
  const [success, setSuccess] = useState<string>();
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    setValue,
    watch,
    trigger
  } = useForm<CalculationData>({
    mode: 'onChange',
    defaultValues: {
      total: '',
      transactions: '',
      accountId: '',
      planId: ''
    }
  });

  useEffect(() => {
    (async () => {
      const [accounts, { activeAccountId }] = await Promise.all([accountService.getAllAsync(), userService.getAsync()]);
      setAccounts(accounts);
      setValue('accountId', activeAccountId);

    })().catch(console.error);
  }, []);

  const accountId = watch('accountId');

  useEffect(() => {
    (async () => {
      const plans = await planService.getAllAsync(accountId);
      setPlans(plans);
      setValue('planId', plans[0].id);
    })().catch(console.error);
  }, [accountId]);

  const processSubmit = async ({ accountId, note, planId, transactions, total }: CalculationData) => {
    setInitiated(true);

    const {
      calculatedAmount,
      everything,
      remainingAmount
    } = calculateAmount(total, transactions);

    if (calculatedAmount > 0) {
      setSubmitting(true);
      const plan = plans.find(({ id }) => id === planId);

      if (!plan) {
        throw new Error(`Plan[${planId}] not found!`);
      }

      const submittedAmounts = await planOperationService.submitForAvailable(plan, {
        amount: calculatedAmount,
        note,
        intent: OperationIntent.Minus
      }, { accountId });

      setPlans(plans.map(plan => plan.id === planId
        ? { ...plan, ...submittedAmounts }
        : plan));

      setSubmitting(false);
    }

    setValue('transactions', '');
    setValue('planId', plans[0].id);

    if (everything) {
      setValue('note', '');
      setValue('total', '');
      setInitiated(false);
      setSuccess('The full amount has been successfully submitted!');
    } else {
      setValue('total', remainingAmount.toString());
      setSuccess(`${calculatedAmount} has been submitted successfully!`);
    }
  };

  const total = watch('total');

  useEffect(() => {
    trigger('transactions');
  }, [total]);

  const canSubmit = isDirty && isValid && !submitting;

  return <ExpenseOperationView backIcon={<QueuePlayNextIcon />} backPath="/add-expenses" title="Calculate Expenses">
    <form onSubmit={handleSubmit(processSubmit)}>
      <ExpenseControl>
        <Controller name="total" control={control}
          rules={{
            required: `It's required!`,
            validate: checkMoney
          }}
          render={({ field, fieldState: { invalid, error } }) =>
            <TextField type="number" label="Total" fullWidth disabled={initiated}
              error={invalid}
              helperText={error?.message}
              {...field} />} />
      </ExpenseControl>

      <ExpenseControl>
        <Controller name="transactions" control={control}
          rules={{
            validate: checkAll(checkTransactions, value => checkConsistency(value, total)),
          }}
          render={({ field, fieldState: { invalid, error } }) =>
            <TextField label="Transactions" placeholder="20 39" fullWidth
              multiline
              error={invalid}
              helperText={error?.message}
              {...field} />} />
      </ExpenseControl>

      <ExpenseControl>
        <Controller name="accountId" control={control}
          render={({ field, fieldState: { invalid, error } }) =>
            <FormControl fullWidth error={invalid}>
              <InputLabel>Account</InputLabel>
              <Select {...field}>
                {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
              </Select>
              {invalid && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>} />
      </ExpenseControl>

      <Controller name="planId" control={control}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) =>
          <FormControl fullWidth error={invalid}>
            <InputLabel>Plan</InputLabel>
            <Select value={value} onChange={onChange}>
              {value && plans.some(({ id }) => id === value)
                ? plans.map(plan => <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>)
                : <MenuItem value={value}></MenuItem>}
            </Select>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>} />

      <ExpenseControl last>
        <Controller name="note" control={control}
          render={({ field }) => <TextField label="Note" multiline fullWidth {...field} />} />
      </ExpenseControl>

      <ExpenseAlert onClose={() => setSuccess(undefined)} message={success} />

      <ExpenseActions>
        <SubmitButton disabled={!canSubmit} loading={submitting} />
      </ExpenseActions>
    </form>
  </ExpenseOperationView>
}