import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface ExpenseAlertProps {
  message?: string;
  error?: boolean;
  onClose?: () => void;
}

export default function ExpenseAlert({ message, onClose, error }: ExpenseAlertProps) {
  if (!message) {
    return <></>;
  }
  return <Box mb={3}><Alert onClose={onClose} severity={error ? 'error' : 'success'}>{message}</Alert></Box>;
}