import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import {
  AuthLayout,
  LoginRoutes,
  Login,
  container,
  ServiceContext,
  Authenticator,
  HttpCommunicator,
  secretHeaderProvider,
  PrivateRoute,
  forbiddenErrorHandler,
  Environment,
  cloneWith,
} from '@ivorobioff/shared';
import AppLayout from './app/components/layouts/AppLayout';
import PlansPage from './app/components/pages/plans/PlansPage';
import DebtsPage from "./app/components/pages/DebtsPage";
import SavingsPage from "./app/components/pages/SavingsPage";
import TransactionsPage from "./app/components/pages/TransactionsPage";
import PlanService from './app/services/PlanService';
import { DebtService } from './app/services/DebtService';
import { SavingService } from './app/services/SavingService';
import { TransactionService } from './app/services/TransactionService';
import { BudgetService } from './app/services/BudgetService';
import { createBrowserHistory } from 'history';
import SettingsPage from './app/components/pages/SettingsPage';
import { UserService } from './app/services/UserService';
import InviteService from './app/services/InviteService';
import SignUpPage from './app/components/pages/SignUpPage';
import TermService from './app/services/TermService';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import PreferenceService from './app/services/PreferenceService';
import AccountService from './app/services/AccountService';
import AccountPage from './app/components/pages/account/AccountPage';
import AddExpensesPage from './app/components/pages/expenses/AddExpensesPage';
import FastLayout from './app/components/layouts/FastLayout';
import { isSmallScreen } from './app/random/utils';
import AllocationPage from './app/components/pages/allocation/AllocationPage';
import { AllocationService } from './app/services/AllocationService';
import AddExpensesWithAiPage from './app/components/pages/expenses/AddExpensesWithAiPage';
import PocService from './app/services/PocService';
import PlanOperationService from './app/services/PlanOperationService';
import DebtOperationService from './app/services/DebtOperationService';
import SavingOperationService from './app/services/SavingOperationService';
import BudgetOperationService from './app/services/BudgetOperationService';
import CalculateExpensesPage from './app/components/pages/expenses/CalculateExpensesPage';

const site = {
  url: 'https://blin.familythings.cloud',
  name: 'Blin!'
}

container.registerFactory('env', () => {
  return cloneWith({
    site,
    baseUrl: 'https://blin.familythings.cloud',
    apiBaseUrl: 'http://localhost:8080/api/v1.0',
    pocBaseUrl: 'https://poc.familythings.cloud/api',
  }, window.__ENV__);
});

container.registerType(BudgetService);
container.registerType(BudgetOperationService);
container.registerType(PlanService);
container.registerType(PlanOperationService);
container.registerType(DebtService);
container.registerType(DebtOperationService);
container.registerType(SavingService);
container.registerType(SavingOperationService);
container.registerType(TransactionService);
container.registerType(Authenticator);
container.registerType(UserService);
container.registerType(InviteService);
container.registerType(TermService);
container.registerType(PreferenceService);
container.registerType(AccountService);
container.registerType(AllocationService);
container.registerType(PocService);

container.registerFactory('history', () => createBrowserHistory());

// normal
container.registerFactory('http', container => {
  return new HttpCommunicator({ baseUrl: container.get<Environment>('env').apiBaseUrl })
});

// secured
container.registerFactory('https', container => {
  return new HttpCommunicator({
    baseUrl: container.get<Environment>('env').apiBaseUrl,
    requestOptionsProvider: secretHeaderProvider(container.get(Authenticator)),
    errorHandler: forbiddenErrorHandler(container.get(Authenticator))
  })
});

container.get(Authenticator).watch();

const theme = createTheme({
  palette: {
    primary: {
      main: '#00897b',
    },
    secondary: orange,
  }
});

const routes: LoginRoutes = {
  signUp: '/sign-up'
};

export default function App() {
  return (<ThemeProvider theme={theme}>
    <ServiceContext.Provider value={container}>
      <Router history={container.get('history')}>
        <Switch>
          <PrivateRoute container={container} exact path={[...(isSmallScreen() ? [] : ['/']), '/plans']}>
            <AppLayout container={container}>
              <PlansPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/debts">
            <AppLayout container={container}>
              <DebtsPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/savings">
            <AppLayout container={container}>
              <SavingsPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/transactions">
            <AppLayout container={container}>
              <TransactionsPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/allocation">
            <AppLayout container={container}>
              <AllocationPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/settings">
            <AppLayout container={container}>
              <SettingsPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/accounts">
            <AppLayout container={container}>
              <AccountPage container={container} />
            </AppLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path={[...(isSmallScreen() ? ['/'] : []), '/add-expenses']}>
            <FastLayout container={container}>
              <AddExpensesPage container={container} />
            </FastLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/calculate-expenses">
            <FastLayout container={container}>
              <CalculateExpensesPage />
            </FastLayout>
          </PrivateRoute>
          <PrivateRoute container={container} exact path="/add-expenses-with-ai">
            <FastLayout container={container}>
              <AddExpensesWithAiPage container={container} />
            </FastLayout>
          </PrivateRoute>
          <Route exact path="/sign-in">
            <AuthLayout title="Sign-In" site={site}>
              <Login container={container} routes={routes} />
            </AuthLayout>
          </Route>
          <Route exact path="/sign-up">
            <AuthLayout title="Sign-Up" site={site}>
              <SignUpPage container={container} />
            </AuthLayout>
          </Route>
        </Switch>
      </Router>
    </ServiceContext.Provider>
  </ThemeProvider>)
};