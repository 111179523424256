import useService from '@ivorobioff/shared/src/ioc/useService';
import { Box, IconButton, Paper, styled, Typography } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { History } from 'history';

const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export interface ExpenseOperationViewProps {
  title: string;
  backIcon: ReactElement;
  backPath: string;
  children: ReactNode;
}

export default function ExpenseOperationView({ title, backIcon, backPath, children }: ExpenseOperationViewProps) {
  const history = useService<History>('history');

  return <Box marginTop={3} paddingX={1}>
    <Paper variant="outlined">
      <Box padding={2}>
        <PageHeader>
          <Typography component="h2" variant="h6" color="primary">{title}</Typography>
          <IconButton color="secondary" onClick={() => history.push(backPath)}>
            {backIcon}
          </IconButton>
        </PageHeader>
        <br />
        {children}
      </Box>
    </Paper>
  </Box>;
}
