import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';

export interface SubmitButtonProps {
  disabled: boolean;
  loading: boolean;
}

export default function SubmitButton({ disabled, loading }: SubmitButtonProps) {
  return <Box component="div" position="relative" display="inline-block">
    <Button variant="contained"
      color="primary"
      type="submit"
      disabled={disabled}>Submit</Button>
    {loading && <Box
      position="absolute"
      top="0"
      marginTop={0.8}
      marginLeft={-1.3}
      left="50%"><CircularProgress size={24} /></Box>}
  </Box>;
}