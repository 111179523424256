import { HttpCommunicator, Container } from "@ivorobioff/shared";
import { Observable } from "rxjs";
import User, { UserToUpdate, UserToCreate } from "../models/User";

export class UserService {

  private https: HttpCommunicator;
  private http: HttpCommunicator;

  constructor(container: Container) {
    this.https = container.get('https');
    this.http = container.get('http');
  }

  update(updates: UserToUpdate): Observable<any> {
    return this.https.patch('/users/current', updates);
  }

  create(user: UserToCreate): Observable<User> {
    return this.http.post('/users', user);
  }

  get(): Observable<User> {
    return this.https.get('/users/current');
  }

  getAsync(): Promise<User> {
    return new Promise((resolve, reject) => this.get().subscribe(resolve, reject));
  }
}